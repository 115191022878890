import React from 'react';
import {useQuery, useMutation} from "@apollo/client";
import gql from 'graphql-tag';
import { useCustomerContext } from "../contexts/CustomerContext";

export default function CustomerInfo({location}) {

    const { customer, setCustomer } = useCustomerContext();

    const query = gql`
        query {
            customer {
                firstname
                lastname
            }
        }`;

    const {error, loading, data} = useQuery(query, {
        fetchPolicy: 'network-only',
        onCompleted: data => setCustomer({ ...customer, name: `${data.customer.firstname} ${data.customer.lastname}` }),
        onError: error => setCustomer({}),
    });

    return (
        <>
            <h1>Customer info page</h1>

            <div>Your Token is: {customer.token}</div>
            <div>Your name is: <strong>{customer.name}</strong></div>
        </>
    );
}

